<template>
    <div>
        <el-drawer
            title="AMR故障操作"
            :visible.sync="drawer"
            :direction="'rtl'"
            :before-close="handleClose"
        >
            <div style="width: 90%; margin: 0 auto">
                <el-descriptions
                    title="当前选中的维修人员信息"
                    v-if="selectItem"
                    :column="2"
                    direction="vertical"
                >
                    <el-descriptions-item label="维修人员姓名">{{ selectItem.name }}</el-descriptions-item>
                    <el-descriptions-item label="维修人员ID">{{ selectItem.id }}</el-descriptions-item>
                    <el-descriptions-item label="所属部门">{{ selectItem.partment }}</el-descriptions-item>
                </el-descriptions>

                <el-button @click="showPopSelector" v-if="!selectItem">选择维修人员</el-button>
                <div class="layoutBorder">
                    <el-button @click="showPopSelector" v-if="selectItem">重新选择维修人员</el-button>
                </div>

                <el-divider></el-divider>

                <el-button type="success" @click="sumbit()" v-if="selectItem">确认无误并生成工单</el-button>
            </div>
        </el-drawer>

        <infoHeader :content_name="'工单详情'" />

        <el-descriptions
            title="工单信息表"
            direction="vertical"
            :column="3"
            border
            v-if="workOrderInfo"
        >
            <el-descriptions-item label="工单编号">{{ workOrderInfo.ticket_number }}</el-descriptions-item>
            <el-descriptions-item label="工单描述">{{ workOrderInfo.issue_description }}</el-descriptions-item>
            <el-descriptions-item label="工单状态">{{ workOrderInfo.status }}</el-descriptions-item>
            <el-descriptions-item label="工单等级">{{ workOrderInfo.priority }}</el-descriptions-item>
            <el-descriptions-item label="工单类型" :span="1">{{ workOrderInfo.order_type }}</el-descriptions-item>
            <el-descriptions-item label="车库id" :span="1">{{ workOrderInfo.garage_id }}</el-descriptions-item>
        </el-descriptions>

        <el-divider content-position="left">工单操作</el-divider>

        <el-form :inline="true">
            <el-form-item>
                <el-button type="primary" @click="handleOrder">指派工单</el-button>
                <el-button type="info" @click="dialogEditFormVisible = true">更新工单</el-button>
            </el-form-item>
        </el-form>

        <el-divider content-position="left">更多内容</el-divider>

        <el-row :gutter="30">
            <el-col :span="12">
                <el-card shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>工单沟通窗口</span>
                        <el-button
                            style="float: right; padding: 3px 0"
                            type="text"
                            @click="getCommunicateContent"
                            >更新沟通内容</el-button
                        >
                    </div>
                    <div class="chat-message-list">
                        <div
                            v-for="(message, index) in messages"
                            :key="index"
                            class="chat-message"
                        >
                            <div class="chat-message-sender">{{ message.sender }}</div>
                            <div class="chat-message-content">{{ message.create_time }} : {{ message.content }}</div>
                        </div>
                    </div>
                    <div class="bottom clearfix">
                        <el-input
                            placeholder="请输入内容"
                            v-model="input"
                            clearable
                            style="width: 500px; margin-right: 15px"
                        >
                        </el-input>
                        <el-button type="text" class="button" @click="handleSumbitReply">发送</el-button>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>工单处理记录</span>
                        <el-button
                            style="float: right; padding: 3px 0"
                            type="text"
                            >提交工单记录</el-button
                        >
                    </div>
                    <el-table :data="recordata" style="width: 100%">
                        <el-table-column
                            prop="delivery_time"
                            label="更新时间"
                            width="180"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="operating_change_type"
                            label="维修事件"
                            width="150"
                        >
                        </el-table-column>
                        <el-table-column prop="change_content" label="维修内容">
                        </el-table-column>
                    </el-table>
                </el-card>
            </el-col>
        </el-row>

        <!-- 弹窗组件 -->
        <CommonPopSelectorVue
            title="运维人员名单"
            :visible.sync="popSelectorVisible"
            :table-data="mockTableData"
            :table-columns="tableColumns"
            :table-button="tableButton"
            @selectItem="handleSelectItem"
        />

        <!-- 编辑工单弹窗 -->
        <el-dialog title="编辑工单" :visible.sync="dialogEditFormVisible">
            <el-form :model="editData">
                <el-form-item label="工单等级" :label-width="'120px'">
                    <el-select
                        v-model.number="editData.priority"
                        placeholder="请选择"
                    >
                        <el-option label="一级" :value="1"></el-option>
                        <el-option label="二级" :value="2"></el-option>
                        <el-option label="三级" :value="3"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogEditFormVisible = false">取消</el-button>
                <el-button type="primary" @click="editWorkOrder">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { work_order_get, work_order_update, work_order_communicate_content, work_order_sumbit_reply } from '@/api/partner/garageManage/work_order/work_order.js';
import CommonPopSelectorVue from '@/components/shibo_Common/CommonPopSelector.vue';

export default {
    name: 'start',
    data() {
        return {
            querydata: null,
            input: '',
            messages: [
                { sender: 'sender', content: '暂无沟通内容，请点击更新沟通内容查看' },
            ],
            workOrderInfo: {},
            mockTableData: [
                { name: '梁苏皖', id: 1, partment: '运维部' },
                { name: '刘德华', id: 2, partment: '运维部' },
                { name: '胡正东', id: 3, partment: '运维部' }
            ],
            tableColumns: [
                { prop: 'name', label: '姓名' },
                { prop: 'id', label: '编号' },
                { prop: 'partment', label: '部门' }
            ],
            tableButton: [{ name: '选择' }, { name: '删除' }],
            recordata: [],
            ticket_number: null,
            drawer: false,
            popSelectorVisible: false,
            selectItem: null,
            dialogEditFormVisible: false,
            editData: {
                ticket_number: '',
                priority: ''
            },
            isPolling: true, // 用于控制轮询的标志
            pollingTimer: null // 用于存储定时器的引用
        };
    },
    components: {
        CommonPopSelectorVue
    },
    created() {
        this.ticket_number = this.$route.query.ticket_number;
        this.getList(); // 页面加载时执行
        this.pollForUpdates(); // 开始长轮询
    },
    beforeDestroy() {
        this.isPolling = false; // 页面销毁前停止轮询
        if (this.pollingTimer) {
            clearTimeout(this.pollingTimer); // 清除定时器
        }
    },
    methods: {
        handleSumbitReply() {
            work_order_sumbit_reply({ ticket_number: this.ticket_number, content: this.input }).then(res => {
                if (res.code == 200) {
                    this.input = '';
                    this.getCommunicateContent();
                }
            });
        },
        getCommunicateContent() {
            work_order_communicate_content({ ticket_number: this.ticket_number }).then(res => {
                this.messages = res.data;
                console.log(this.messages);
            });
        },
        getList() {
            work_order_get({ ticket_number: this.ticket_number }).then(res => {
                const { workorder } = res.data;
                this.workOrderInfo = workorder;
                this.editData.ticket_number = workorder.ticket_number; // 设置编辑表单的数据
            });
        },
        handleSelectItem(item) {
            this.selectItem = item;
        },
        showPopSelector() {
            this.popSelectorVisible = true;
        },
        handleClose(done) {
            done();
        },
        handleOrder() {
            this.$router.push({
                name: 'work_Create',
                query: {
                    ticket_number: this.ticket_number
                }
            });
        },
        editWorkOrder() {
            work_order_update(this.editData)
                .then(() => {
                    this.$message.success('编辑工单成功');
                    this.getList(); // 刷新工单信息
                    this.dialogEditFormVisible = false;
                })
                .catch(error => {
                    this.$message.error(error);
                });
        },
        pollForUpdates() {
            const poll = () => {
                if (!this.isPolling) return; // 如果停止轮询则退出
                work_order_communicate_content({ ticket_number: this.ticket_number }).then(res => {
                    if (!this.isPolling) return; // 再次检查以确保停止轮询
                    this.messages = res.data;
                    console.log(this.messages);
                    this.pollingTimer = setTimeout(poll, 5000); // 每5秒进行一次轮询
                }).catch(err => {
                    console.error(err);
                    if (!this.isPolling) return; // 再次检查以确保停止轮询
                    this.pollingTimer = setTimeout(poll, 5000); // 在出错时也继续轮询
                });
            };
            poll();
        }
    }
};
</script>


<style lang="scss" scoped>
.bottom {
    margin-top: 13px;
    line-height: 12px;
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: '';
    }

    .clearfix:after {
        clear: both;
    }
    .chat-message-list {
        flex: 1;
        overflow-y: auto;
    }

    .chat-message {
        margin-bottom: 10px;
    }

    .chat-message-sender {
        font-weight: bold;
    }
}
</style>
